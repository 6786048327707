import React from "react"
import { graphql } from "gatsby"

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"

import Layout from "../../components/en/Layout"
import HomeSectionInfinite from "../../components/en/HomeSectionInfinite"
import Breadcrumbs from "../../components/en/Breadcrumbs"
import SEO from "../../components/en/SEO"

const TagTemplate = ({ data }) => {
  const tag = data.tagsEn
  const cards = data.allCardsEn.edges

  const metaDescription =
    tag.description ||
    `Access the best content about spirituality, history and islam
    wisdom with our hashtags`

  return (
    <Layout>
      <SEO
        title={tag.name}
        description={metaDescription}
        card={false}
        canonical={`${process.env.GATSBY_HOST_URL}/en/hashtags/${tag.slug}`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage={tag.name}
            secondaryLink={"/en/hashtags"}
            secondaryLinkName="Hashtags"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="topics-header">
            <h1>{tag.name}</h1>
            <p>
              Access the best content about spirituality, history and islam
              wisdom with our hashtags
            </p>
          </div>

          <HomeSectionInfinite
            name=""
            cards={cards}
            style={{ marginTop: 50, marginBottom: 50 }}
            customClass="home-section-card-list"
          />
        </div>
      </div>
    </Layout>
  )
}

export default TagTemplate

export const query = graphql`
  query($id: String!, $slug: String!) {
    tagsEn(id: { eq: $id }) {
      name
      slug
      createdAt
    }

    allCardsEn(filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
      edges {
        node {
          name
          slug
          imageUrl
          type
          topic {
            name
            color
          }
          tags {
            name
          }
          meta {
            content
            author
          }
        }
      }
    }
  }
`
